@import "~@anna-money/anna-web-ui/dist/theme2";

body {
  background-color: #ffffff;
  color: $color-secondary-900;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding-inline-start: 15px;
}

ol {
  padding: 0 0 0 14px;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  padding: 0;
  margin: 0;
  font-weight: 400;
}

ol ul {
  padding: 0 0 0 24px;
}

button {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

// remove after move to new controls design
input:disabled,
input:hover:disabled {
  background: $color-secondary-400;
  opacity: 1;
}
