@import "~@anna-money/anna-web-ui/dist/theme2";

.link {
  color: $color-primary-600;
  cursor: pointer;
  font-size: $text-primary-size-small;
  line-height: $text-primary-height-medium;
  text-decoration: none;

  &:hover {
    color: $color-primary-500;
  }
}
