.scaffold {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.header {
  @media (width <= 640px) {
    display: none;
  }
}

.content {
  position: relative;
  flex: 1 1 100%;
}
