@import "~@anna-money/anna-web-ui/dist/theme2";

.terms {
  max-width: 835px;
  box-sizing: border-box;
  padding: 0 $space-s;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  margin: 30px auto 65px;

  .block {
    padding: 24px 0;
    border-bottom: 1px solid $color-secondary-500;

    &:first-child {
      padding-top: 0;
    }
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    white-space: break-spaces;
  }

  @media (width <= 640px) {
    width: 100%;
  }

  ol ul {
    list-style-type: lower-roman;
  }

  .button {
    margin-top: 60px;
  }
}
