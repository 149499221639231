.loader {
  &.mode-inline {
    position: relative;
    display: inline-block;
  }

  &.align-center {
    text-align: center;
  }
}

.splashscreenWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
