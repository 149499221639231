@import "~@anna-money/anna-web-ui/dist/theme2";

.footer {
  width: 100%;
  max-width: 835px;
  box-sizing: border-box;
  padding: 0 $space-s;
  margin: $space-l auto $space-m;
  font-size: $text-primary-size-small;
  line-height: $text-primary-height-medium;

  @media only screen and (width <= 600px) {
    padding: 0 20px;
  }

  .link {
    color: $color-brand;
    font-weight: bold;
  }
}
