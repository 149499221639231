@import "~@anna-money/anna-web-ui/dist/theme2";

.text {
  &.size-l {
    font-size: $text-secondary-size-large;
    line-height: $text-secondary-height-large;
  }

  &.size-m {
    font-size: $text-primary-size-small;
    line-height: $text-primary-height-medium;
  }

  &.size-s {
    font-size: 13px;
    line-height: $text-tertiary-height-medium;
  }

  &.weight-semibold {
    font-weight: $text-weight-bold;
  }

  &.color-white {
    color: $color-secondary-100;
  }
}
