@import "~@anna-money/anna-web-ui/dist/theme2";

.page {
  max-width: 835px;
  padding: 24px 25px 16px;
  border: 1px solid $color-secondary-500;
  border-radius: 4px 4px 0 0;
  margin: 30px auto 65px;

  @media (width <= 640px) {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
    border: 0;
  }

  .action {
    margin-top: $space-m;
  }

  .company,
  .email {
    white-space: nowrap;
  }
}
