@import "~@anna-money/anna-web-ui/dist/theme2";

.header {
  display: flex;
  height: 80px;
  align-items: center;
  padding: 0 47px;
  border: 1px solid $color-secondary-500;
}

@media only screen and (width <= 600px) {
  .header {
    padding: 0 20px;
  }
}
