@import "~@anna-money/anna-web-ui/dist/theme2";

.formErrorView {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (width <= 640px) {
    padding: 0 $space-m;
  }
}

.image {
  width: 160px;
  height: 160px;
}

.text {
  margin: $space-xxs 0;
}
